import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import DropdownDatepicker from "@/containers/add-entity-panel/panel/attribute-holder/form-elements/dropdown-datepicker.vue";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import {mapGetters} from "vuex";

export default {
    name: "AttributeHolder",
    props: {
        attributes: {
            type: Array,
            default() {
                return [];
            },
        },
        title: String,
        indent: Boolean,
        entityValue: String,
    },
    components: {
        "v-select": vSelect,
        FilePond,
        multiselect: Multiselect,
        DropdownDatepicker,
    },
    data() {
        return {
            form_attributes: [],
            entitiesRelationsAttributes: {
                entities: [],
                relations: [],
                attributes: [],
            },
            date: new Date().getFullYear(),
        };
    },
    watch: {
        form_attributes: {
            handler: function(val) {
                this.attribute_data = this.form_attributes;
            },
            deep: true,
        },
    },
    methods: {
        onDateChange(day, month, year) {},
        tempChange(e, field) {
            let temp = [];
            temp.push(e.year ?? "");
            temp.push(e.month ?? "");
            temp.push(e.day ?? "");
            const val = temp.join("-");
            field.value = val;
        },
        updateAttributes() {
            this.form_attributes = [];
            this.attribute_data.forEach((e) => {
                let attribute = this.getEntitiesRelationAttributes.attributes.find((f) => f._id == e.attribute_id);
                let att_value;
                if ("value" in e) {
                    att_value = e.value;
                } else {
                    att_value = e.primary ? this?.entityValue ?? attribute.default_value : attribute.default_value;
                }
                if (attribute) {
                    let attribute_data = {
                        ...e,
                        required: e.required === "true" || e.required,
                        value: att_value,
                        label: attribute.label,
                        type: attribute.type,
                        options: attribute?.options ?? [],
                    };
                    this.form_attributes.push(attribute_data);
                }
            });
        },
    },
    computed: {
        attribute_data: {
            get() {
                return this.attributes;
            },
            set(val) {
                this.attributes = val
                    .filter((e) => e)
                    .map((e) => {
                        return {
                            attribute_id: e.attribute_id,
                            value: e.value,
                            _id: e._id,
                        };
                    });
                this.$emit("onChange", this.attributes);
            },
        },
        getEntitiesRelationAttributes() {
            return this.$store.getters.getEntitiesRelationsAttributesData;
        },
    },
    async mounted() {
        this.updateAttributes();
    },
};
