<template>
    <div class="">
        <div :class="menuClasses">
            <div class="offcanvas-overlay" v-if="isOpen"></div>
            <div class="offcanvas-content">
                <nav class="tw-h-16 tw-bg-white tw-border-b-2 tw-p-2 tw-flex tw-justify-between tw-gap-8 tw-static tw-z-50">
                    <div class="tw-flex tw-items-center tw-w-1/2">
                        <div class="tw-w-10 tw-mr-2">
                            <div class="tw-mx-2 tw-w-8 tw-h-8 tw-rounded-full tw-bg-blue-200 tw-flex tw-justify-center tw-items-center">
                                <img src="./assets/user.svg" />
                            </div>
                        </div>
                        <div v-show="!editName" class="tw-flex tw-truncate tw-justify-between" :title="getEntityData.entity">
                            <div class="tw-truncate">{{ getEntityData.entity }}</div>
                            <span @click="editName = true" class="tw-cursor-pointer tw-self-center hover:tw-text-brand-hover tw-text-brand">
                                <svg title="Edit" class="tw-w-5 tw-ml-3 tw-h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11  5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                                </svg>
                            </span>
                        </div>
                        <div v-show="editName" class="bootstrap-styles tw-border tw-w-full tw-p-2 tw-rounded-md tw-flex tw-justify-between">
                            <b-form-input v-model="getEntityData.entity" placeholder="Enter entity name"></b-form-input>
                            <div @click="editName = false" class="tw-flex tw-items-center tw-mr-2">
                                <svg title="Save" width="24" height="24" viewBox="0 0 24 24" class="tw-outline-none tw-h-6 tw-w-6 tw-text-brand tw-cursor-pointer hover:tw-text-brand-hover tw-ml-3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3ZM19 19H5V5H16.17L19 7.83V19ZM12 12C10.34 12 9 13.34 9 15C9 16.66 10.34 18 12 18C13.66 18 15 16.66 15 15C15 13.34 13.66 12 12 12ZM6 6H15V10H6V6Z" fill="#0D69D5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center tw-gap-2">
                        <button @click="updateEntity" :disabled="loadingBtn" :class="loadingBtn ? 'tw-text-base tw-bg-gray-100 tw-text-gray-400 tw-cursor-not-allowed tw-border-gray-400' : 'tw-text-base tw-bg-white tw-text-brand hover:tw-bg-brand hover:tw-text-white tw-cursor-pointer tw-border-blue-600'" class="tw-border tw-border-solid tw-rounded-lg tw-py-2 tw-px-4 tw-h-8 tw-flex tw-items-center">
                            Save
                        </button>

                        <div class="tw-flex tw-items-center tw-gap-2">
                            <div title="Case Identifiers" class="tw-cursor-pointer tw-rounded-md tw-flex tw-items-center tw-justify-center tw-h-8 tw-w-8" :style="`background-color: ${confidenceScore.bg} ; color: ${confidenceScore.color}`">
                                <span v-if="matchingIdentifier && matchingIdentifier.length"> {{ matchingIdentifier.length }} </span>
                                <span v-else> 0 </span>
                            </div>

                            <div class="status-dropdown tw-flex tw-flex-col tw-relative" v-on-click-outside="closeStatusList">
                                <div title="Status" class="tw-rounded-full tw-cursor-pointer tw-h-8 tw-w-8 tw-p-1 tw-flex tw-items-center" :style="`background-color: ${cardStatus.bg}`" @click="toggleStatusList()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="getEntityData.status === 'CONFIRMED'">
                                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" :fill="cardStatus.color" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="getEntityData.status === 'POTENTIAL'">
                                        <path d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" :fill="cardStatus.color" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="getEntityData.status === 'IRRELEVANT'">
                                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" :fill="cardStatus.color" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="!getEntityData.status">
                                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" :fill="cardStatus.color" />
                                    </svg>
                                </div>

                                <div class="tw-rounded-lg tw-absolute tw-top-10 tw--right-8 tw-z-10 tw-bg-white dropdown_content" v-if="showStatusList">
                                    <div
                                        class="statusOptions tw-cursor-pointer hover:tw-bg-blue-50 tw-px-4"
                                        :class="{
                                            'tw-bg-blue-100': (getEntityData.status && getEntityData.status.toLowerCase() === option.label.toLowerCase()) || (!getEntityData.status && !option.value),
                                        }"
                                        v-for="(option, oi) in statusOptions"
                                        :key="oi"
                                        @click.stop="changeStatus(option)"
                                    >
                                        <div class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-2" :class="{'tw-border-solid tw-border-0 tw-border-b tw-border-gray-200': oi < statusOptions.length - 1}">
                                            <span :style="`color:${option.color}`"> {{ option.label }} </span>
                                            <div class="tw-rounded-full tw-p-1 tw-h-6 tw-w-6 tw-flex tw-items-center" :class="`tw-bg-${option.bg}-100`">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="option.value === 'confirmed'">
                                                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" :fill="option.color" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="option.value === 'potential'">
                                                    <path d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" :fill="option.color" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="option.value === 'irrelevant'">
                                                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" :fill="option.color" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="tw-self-center tw-h-4 tw-w-4 tw-mx-auto" v-if="!option.value">
                                                    <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" :fill="option.color" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <span :title="getEntityData.run_intelligence ? 'Intelligence Completed' : 'Intelligence Not Completed'" class="tw-cursor-pointer tw-h-8 tw-w-8 tw--mx-1.5 tw-p-0.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" :class="getEntityData.run_intelligence ? 'tw-text-gray-500' : 'tw-text-blue-600'" :stroke="getEntityData.run_intelligence ? '#85898E' : '#0D69D5'" stroke-width="3">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                </svg>
                            </span>
                            <div class="tw-mx-2 tw-cursor-pointer" @click="refreshData">
                                <img src="./assets/cross.svg" />
                            </div>
                        </div>
                    </div>
                </nav>

                <div class="tw-flex main-container" v-if="isLoading">
                    <span class="tw-absolute tw-px-4 tw-py-2 tw-text-center" style="left: calc(50% - 128px); top: calc(50% - 128px)">
                        <div class="spinner-4" style="margin-left: calc(50% - 15px)"></div>
                        <span class="tw-ml-2">Please wait... Fetching data</span>
                    </span>
                </div>
                <div class="tw-flex main-container" v-else>
                    <aside class="tw-w-52 tw-h-screen tw-cursor-pointer tw-text-base-content tw-border-r-2">
                        <ul>
                            <li v-for="item in filteredTabs" :key="item.id" class="tw-pl-3 tw-border-b-2 tw-h-10 hover:tw-bg-blue-100 tw-list-none" @click="selectTab(item)" :class="{'tw-bg-blue-200 ': isActive(item)}">
                                <div class="tw-flex tw-items-center" :class="{'tw-border-r-4 tw-border-blue-600': isActive(item)}">
                                    <span class="client-card-info tw-self-stretch tw-w-56 tw-text-sm tw-text-black-600 tw-font-small tw-capitalize tw-px-2 tw-py-2">
                                        {{ item.name }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </aside>
                    <div id="more-info-container" class="main-container tw-p-5 tw-pt-0 tw-max-h-screen tw-w-full tw-overflow-auto">
                        <component :is="selectedTab" :ref="selectedTab" :entity="getEntityData" :relationshipTypes="relationshipTypes" :currentTab="currentTab"> </component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Attributes from "./Attributes";
import Relationships from "./Relationships";
import Notes from "./Notes";
import AssociatedRisks from "./Associated-Risk";
import Attachments from "./Attachments";
import Monitoring from "./Monitoring";
import Details from "./Details";
import {FEATURES} from "@/utils/constants";
import {mapActions, mapGetters} from "vuex";
import {uniq, cloneDeep} from "lodash";
import axios from "@/axios";
import {EventBus} from "@/main.js";

export default {
    components: {
        Attributes,
        Relationships,
        Notes,
        AssociatedRisks,
        Attachments,
        Monitoring,
        Details,
    },
    props: {
        isOpen: Boolean,
        position: String,
        entityData: Object,
        currentTab: String,
    },
    data() {
        return {
            selectedTab: "Attributes",
            sidebarTabs: [
                {
                    id: "Attributes",
                    name: "Attributes",
                },
                {
                    id: "Relationships",
                    name: "Relationships",
                },
                {
                    id: "Notes",
                    name: "Notes",
                },
                {
                    id: "AssociatedRisks",
                    name: "Associated Risks",
                },
                {
                    id: "Attachments",
                    name: "Attachment",
                },
                {
                    id: "Monitoring",
                    name: "Monitoring",
                },
            ],
            attributeData: {
                companyNumber: "",
                incorporation_date: "",
                dissolution_date: "",
                country: "",
                state: "",
                firstName: "",
                middleName: "",
                lastName: "",
                dob: "",
                gender: "",
                showGraph: true,
                graphLabel: "",
            },
            editName: false,
            loadingBtn: false,
            showStatusList: false,
            statusOptions: [
                {
                    label: "Confirmed",
                    value: "confirmed",
                    color: "#67B52B",
                    bg: "green",
                    icon: "check_solid",
                },
                {
                    label: "Potential",
                    value: "potential",
                    color: "#EB3131",
                    bg: "red",
                    icon: "question_solid",
                },
                {
                    label: "Irrelevant",
                    value: "irrelevant",
                    color: "rgba(107, 114, 128, 1)",
                    bg: "gray",
                    icon: "xmark",
                },
                {
                    label: "Unknown",
                    value: null,
                    color: "rgba(107, 114, 128, 1)",
                    bg: "gray",
                    icon: "minus",
                },
            ],
            relationshipTypes: [],
            isLoading: false,
        };
    },
    async mounted() {
        this.isLoading = true;
        await this.getRelationships();
        this.isLoading = false;
    },
    computed: {
        ...mapGetters(["getPermissions", "getCaseId"]),
        getSelectedEntityData() {
            return this.$store.getters.getSelectedEntityData;
        },

        getEntitiesRelationsAttributes() {
            return this.$store.getters.getEntitiesRelationsAttributesData;
        },

        getEntitiesIdentifiers() {
            return this.$store.getters.getNodesInfo;
        },

        menuClasses() {
            return {
                "offcanvas-menu": this.isOpen,
                "offcanvas-menu--right": this.isOpen && this.position === "right",
            };
        },
        filteredTabs() {
            return this.sidebarTabs.filter((tab) => {
                if (tab.id === "Monitoring") return this.isMonitoringAuthorized(this.getEntityData.entity_type);
                else return tab;
            });
        },
        confidenceColor() {
            return (this.getEntityData?.confidence_score?.name).toLowerCase();
        },
        getEntityData: {
            get() {
                return this.entityData;
            },
            set(val) {
                this.entityData = val;
            },
        },

        confidenceScore() {
            let color, bg;
            if (this.getEntityData.confidence_score?.name?.toLowerCase() === "high") {
                bg = "rgba(209, 250, 229, 1)";
                color = "#67B52B";
            } else if (this.getEntityData.confidence_score?.name?.toLowerCase() === "medium") {
                bg = "rgb(255 219 181)";
                color = "rgb(249 149 0)";
            } else if (this.getEntityData.confidence_score?.name?.toLowerCase() === "low") {
                bg = "rgb(255, 246, 222)";
                color = "rgb(209, 157, 0)";
            } else {
                bg = "rgba(243, 244, 246, 1)";
                color = "#0D69D5";
            }

            return {color, bg};
        },

        cardStatus() {
            let icon, color, bg;
            if (this.getEntityData.status === "CONFIRMED") {
                icon = "check_solid";
                bg = "#F0F8EA";
                color = "#67B52B";
            } else if (this.getEntityData.status === "POTENTIAL") {
                icon = "question_solid";
                bg = "#FBEDED";
                color = "#EB3131";
            } else if (this.getEntityData.status === "IRRELEVANT") {
                icon = "xmark";
                bg = "rgba(243, 244, 246, 1)";
                color = "rgba(107, 114, 128, 1)";
            } else {
                icon = "minus";
                bg = "rgba(243, 244, 246, 1)";
                color = "rgba(107, 114, 128, 1)";
            }

            return {icon, color, bg};
        },
        matchingIdentifier() {
            let data = [];
            for (let index in this.getEntityData.identifiers) {
                delete this.getEntityData.identifiers[index]?.platform;
                data = data.concat(Object.values(this.getEntityData.identifiers[index]));
            }
            return uniq(data.filter((el) => el));
        },
    },
    methods: {
        ...mapActions(["fetchAllResearchScreenData", "getAllResearchData"]),
        selectTab(tab) {
            this.selectedTab = tab.id;
        },
        isActive(item) {
            return this.selectedTab === item.id;
        },
        async updateEntity() {
            this.loadingBtn = true;
            this.getEntityData.relations = this.getEntityData.relation_data?.map(
                (relation) => (
                    delete relation.relation_data,
                    {
                        ...relation,
                        entity: typeof relation.entity === "string" ? relation.entity : relation.entity?._id || "",
                        relation_type: typeof relation.relation_type === "string" ? relation.relation_type : relation.relation_type?._id || "",
                    }
                )
            );

            if (this.getEntityData.entity_type === "social_profile")
                this.getEntityData = {
                    ...this.getEntityData,
                    username: this.getEntityData.entity,
                };
            else if (["url", "passive_media", "active_media"].includes(this.getEntityData.entity_type))
                this.getEntityData = {
                    ...this.getEntityData,
                    url: this.getEntityData.entity,
                };
            await this.$emit("dataChanges", this.getEntityData);
        },

        async refreshData() {
            this.$emit("close");
            EventBus.$emit("refreshData");
        },

        isMonitoringAuthorized(panel_type) {
            if (panel_type === "key_fact") return false;
            if (panel_type === "url") return (panel_type = "passive_media");
            return this.getPermissions.find((permiss) => FEATURES["add_panel_monitor"][permiss] == panel_type);
            // return this.getPermissions
        },

        toggleStatusList() {
            this.showStatusList = !this.showStatusList;
        },

        closeStatusList() {
            this.showStatusList = false;
        },

        changeStatus(option) {
            let card = cloneDeep(this.getEntityData);
            const status = option.value?.toUpperCase() || null;
            delete card.confidence_score;
            this.getEntityData = {
                ...card,
                status,
            };
            // this.$emit("dataChanges", this.getEntityData)
            this.showStatusList = false;
        },

        async getRelationships() {
            this.getSelectedEntityData.entity?.relationship_types?.forEach((e) => {
                let t_relation = this.getEntitiesRelationsAttributes?.relations?.find((f) => f._id == e.relation_id);
                if (t_relation) {
                    this.relationshipTypes.push({
                        _id: t_relation._id,
                        label: t_relation.label,
                        direction: e.direction,
                        arrow_type: t_relation.arrow_type,
                        attributes: t_relation.attributes,
                    });
                }
            });

            this.getEntityData.relation_data = [];

            const {data} = await axios.post(`/entity-identifier/entity-relationship`, {
                case_id: this.getCaseId,
                entity_id: this.entityData._id,
            });
            if (data?.status) {
                data.relation_data.forEach((relation, index) => {
                    let related_entity = this.getEntitiesIdentifiers.entities.find((el) => el._id === relation.entity);
                    if (!related_entity) {
                        related_entity = this.getEntitiesIdentifiers.main_node.find((el) => el._id === relation.entity);
                    }

                    let relation_type = this.getEntitiesRelationsAttributes.relations.find((e) => e._id === relation.relation_type);

                    let rel_data = {
                        ...relation,
                        index: index,
                        relation_type: relation_type || null,
                        entity: related_entity,
                        type: {
                            attributes: relation.attributes_data || [],
                        },
                    };

                    if (!this.getEntityData.relation_data) this.getEntityData.relation_data = [];
                    this.getEntityData.relation_data.push(rel_data);
                    // if (rel_data.relation_type)
                    //     this.relationTypeChange({ attributes: rel_data.attributes_data }, rel_data)
                });
            }
        },
    },
};
</script>
<style lang="scss">
.offcanvas-content {
    .switch {
        width: 40px !important;
        height: 18px !important;
        .slider {
            &:before {
                height: 24px !important;
                width: 24px !important;
                bottom: -3px !important;
            }
        }
        input:checked + .slider:before {
            -webkit-transform: translateX(20px) !important;
            -ms-transform: translateX(20px) !important;
            transform: translateX(20px) !important;
        }
    }
    .main-container {
        height: calc(100vh - 64px);

        .custom-radio {
            .custom-control-label::after {
                top: 3px !important;
                left: -22px !important;
                width: 16px !important;
                height: 16px !important;
                background: no-repeat 50%/50% 50%;
            }
        }
        .neo-datepicker {
            display: flex;
            justify-content: left;
            gap: 8px;
        }
    }
}
</style>

<style scoped lang="scss">
h2 {
    color: black;
}
.offcanvass-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    overflow-x: hidden;
    transition: width 0.5s ease;
    z-index: 9999;
    /* Adjust as needed */
}

.offcanvas-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 998;
}

.offcanvas-content {
    position: fixed;
    top: 0;
    right: 0%;
    width: 85vw;
    height: 100%;
    background-color: #fff;
    z-index: 998;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    /* overflow-y: auto; */
}

.offcanvass-menu-right {
    transition: right 0.5s ease;
}

.dropdown_content {
    border-radius: 8px;
    box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);

    li:hover {
        background-color: #d7d7d7 !important;
    }
}

.spinner-4 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 1px;
    margin-right: 1rem;
    background: conic-gradient(#0000 10%, #0d69d5) content-box;
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s4 1s infinite steps(10);
}
@keyframes s4 {
    to {
        transform: rotate(1turn);
    }
}
</style>
